<template>
    <div class="side-padding" style="padding-top: 10vh; text-align: center;">
        <p>Tippelaget.no er på pause. Vi får se om vi kommer tilbake.</p>
    </div>
    <!-- <div class="tab-content">
        <h2>Brukervilk&aring;r for tippelaget.no</h2>

        <h3>Tjenesten</h3>
        <p>Tippelaget.no er en tjeneste for å organisere (sitt) tippelag.</p>
        <p>Det er ingen spill for penger direkte knyttet til denne tjenesten. Alt av utfylling av kuponger her benyttes til interne konkurranser i hvert registrerte tippelag, mellom hver tipper som benytter systemet og mellom hvert tippelag.</p>

        <h3>Ansvarsfraskrivelse</h3>
        <p>Tippelaget.no har ingen økonomisk ansvar for brukers tipping, verken på tippelaget.no eller om du velger å levere spill med reell innsats hos Norsk Tipping.</p>
        <p>Det er altså ingen økonomisk innsats eller gevinstmulighet på tippelaget.no.</p>
        <p>Tippelaget.no har ingen ansvar for hva medlemmene i et tippelag avtaler seg i mellom når det gjelder innlevering av felles kupong som tippelag hos Norsk Tipping.</p>
        <p>Tippelaget.no fraskriver seg ethvert ansvar om tekniske feil, både i kode og benyttede tekniske tjenester som forårsaker at bruker ikke får levert innen konkurransefrist eller annet.</p>

        <h3>Slette bruker</h3>
        <p>Du kan når som helst selv slette brukeren din, og ingen data som knyttes til deg som person blir tatt vare på.</p>

        <h3>Mobbing</h3>
        <p>Man skal ikke mobbe medlemmer som får 0 rette i en gitt tipperunde. Det er faktisk en ganske god prestasjon, så de fortjener hyllest.</p>
        <p>De som derimot får 2-3 rette skal henges ut for sin ekstremt svake fotball-kunnskap.</p>
    </div> -->
</template>